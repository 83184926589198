import React, { useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import UserContext from '../components/UserContext';
import useGetUser from '../hooks/useGetUser';
import Home_1 from './home/home_1';
import MyHome from './home/[user]';

export default function Home() {
  const { address: walletAddress } = useAccount();
  const { data: user } = useGetUser(walletAddress);
  const { scrollRef } = useContext(UserContext);

  const [widgetsAsHome, setWidgetsAsHome] = useState(false);
  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener('scroll', handleScrollPos);
    return () => {
      window.removeEventListener('scroll', handleScrollPos);
    };
  });

  const handleRedirectToHome = (user) => {
    if (user?.showWidgetsAsHome !== undefined && user.showWidgetsAsHome) {
      //router.push(`/home/${walletAddress}`);
      setWidgetsAsHome(true);
    }
  };

  useEffect(() => {
    handleRedirectToHome(user);
  }, [user]);

  // useEffect(()=> {

  //   let widget = document.getElementsByTagName('widgetbot-crate');
  //   if(widget.length > 0) {
  //    widget[0].style.visibility = "visible";
  //   }
  // }, []);

  const widgetsPageEnabled = walletAddress && user?.showWidgetsAsHome;

  return (
    <div className='relative'>
      {widgetsPageEnabled && (
        <div
          className='left-[100%] z-10  inline-flex w-full rounded-lg bg-white shadow-sm md:absolute md:top-5 md:mb-5 md:mt-0 md:ml-0 md:w-fit md:translate-x-[-110%]'
          role='group'
        >
          <div className='flex w-full items-center justify-center bg-jacarta-700 py-2 md:bg-transparent md:py-0'>
            <button
              type='button'
              onClick={() => setWidgetsAsHome(false)}
              className={`${!widgetsAsHome ? 'opacity-50' : 'opacity-100'
                } text-gray-900 border-gray-200 hover:bg-gray-100 hover:text-blue-700 border-gray-600 hover:bg-gray-600 w-[150px] rounded-l-lg border bg-jacarta-800 px-4 py-2 text-sm font-medium text-white hover:text-white`}
            >
              Default Home
            </button>
            <button
              type='button'
              onClick={() => setWidgetsAsHome(true)}
              className={`${widgetsAsHome ? 'opacity-50' : 'opacity-100'
                } text-gray-900 border-gray-200 hover:bg-gray-100 hover:text-blue-700 border-gray-600 hover:bg-gray-600 w-[150px] rounded-r-md border bg-jacarta-800 px-4 py-2 text-sm font-medium text-white hover:text-white`}
            >
              Custom Home
            </button>
          </div>
        </div>
      )}
      {widgetsAsHome && widgetsPageEnabled ? (
        <MyHome noSpaceMobile={widgetsPageEnabled} />
      ) : (
        <Home_1 />
      )}
    </div>
  );
}

// const newrelicDeps = [
//   '@newrelic/next',
//   'newrelic',
//   'semver',
//   'json-stringify-safe',
//   'readable-stream',
//   'inherits',
//   'util-deprecate',
//   'lru-cache',
//   'yallist',
//   'async',
//   'concat-stream',
//   'buffer-from',
//   'https-proxy-agent',
//   'debug',
//   'ms',
//   'agent-base',
//   '@tyriar/fibonacci-heap',
// ];

// export const config = {
//   unstable_includeFiles: newrelicDeps.map((d) => `node_modules/${d}/**/*.+(js|json)`),
// };
